<template>
    <div class="container">
      <page-title :title="`Bank Information : ${bank.name}`">
        <template slot="button">
          <a href="#" @click.prevent="destroy" class="btn btn-danger float-right"><i class="lni-trash mr-2"></i> Delete</a>
        </template>
        <template slot="button" >
          <router-link :to="{ name: 'banks.edit' }" class="btn btn-warning mr-2 float-right"><i class="lni-pencil mr-2"></i> Edit</router-link>
        </template>
      </page-title>
  
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                     Bank Name:
                      <span>{{ bank.name }}</span>
                    </p>
                    <p>
                      Email address:
                      <span>{{ bank.email_address }}</span>
                    </p>
                    <p>
                      Unique id:
                      <span>{{ bank.unique_id }}</span>
                    </p>
                  </div>
                
                </div>
              </div>
              <hr />
  
             
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.fetch();
    },
  
    data() {
      return {
        bank: {
            name: "",
            email_address: "",
            unique_id: "",
        },
     
      };
    },
  
    methods: {
     
  
      fetch() {
        this.$loader.start()
  
        console.log(this.$route.params.id)
        this.$axios
          .get("/api/v1/dashboard/banks/" + this.$route.params.id)
          .then((response) => {
            this.bank = response.data;
            this.$loader.stop()
          })
          .catch((error) => {
            this.$http(error.response);
            this.$loader.stop()
          });
      },
  
      
      destroy() {
        this.$axios.delete(`/api/v1/dashboard/banks/${this.bank.id}`).then(() => {
          this.$router.push({ name: 'banks.index' })
        }).catch(error => {
          this.$http.serverError(error.response)
        })
      },
  
    
  
  
    },
  };
  </script>
  